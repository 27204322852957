<template>
  <McLayoutForm :heading="$t('installations.EnterInsuranceDetails')">
    <div v-if="loadingState">
      <McSpinner large />
    </div>
    <div v-else-if="errorState">
      <h1>{{ $t('general.Error') }}</h1>
      <p>{{ $t('installations.InvalidLinkPleaseContactUs') }}</p>
    </div>
    <div v-else>
      <b-card class="mb-4"><div v-html="$t('installations.InsuranceDetailsDescription')"/></b-card>
      <McForm ref="mcFormInstance" :onSubmit="handleSubmit">
        <b-card v-for="(tracker, tid) in insuranceDetails" v-bind:key="tid" :title="insuranceDetails.length > 1 ? 'Tracker ' + (tid + 1) : ''" class="pt-3 mb-3">
          <h3>{{ $t('installations.InsuranceCustomer') }}</h3>
          <ContactFormInner v-model="tracker.installation.customer" skipEmail skipPhone allowEmpty skipCountry />
          <h3>{{ $t('installations.VehicleAndInsurance') }}</h3>
          <TrackerInstallationFormInner :isInsurance="true" :subscription="tracker.subscription" v-model="tracker.installation" />
          <h3>{{ $t('alarms.ContactsFormInsurance') }}</h3>
          <p>{{ $t('alarms.ContactsFormInsuranceDescription') }}</p>
          <TrackerAlarmsInsuranceContactsInner v-model="tracker.insuranceSettings" />
        </b-card>
      </McForm>

      <!--pre>{{ $route.params }}</pre!-->
      <!--pre>{{ insuranceDetails }}</pre-->
    </div>
  </McLayoutForm>
</template>

<script>
import { FormatError } from '../../helpers/ErrorFormatting'
import InstallationService from '../../services/InstallationService'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      errorState: false,
      loadingState: true,
      insuranceDetails: null,
    }
  },
  created() {
    this.getInsuranceDetailsBySecret(this.$route.params.secret)
  },
  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    getInsuranceDetailsBySecret(secret) {
      return new Promise((resolve, reject) => {
        InstallationService.getInsuranceDetailsBySecret(secret)
          .then(data => {
            this.loadingState = false
            this.insuranceDetails = data.insuranceDetails
            resolve(data)
          })
          .catch(error => {
            console.error(FormatError(error))
            this.loadingState = false
            this.errorState = true
          })
      })
    },
    handleSubmit(e) {
      return InstallationService.setInsuranceDetailsBySecret(this.insuranceDetails, this.$route.params.secret).then(
        data => {
          this.toastSuccess(this.$t('installations.InsuranceDetailsSaved'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error saving insurance details', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
