<template>
  <div>
    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.Insurer')"
      v-model="curData.vehicleDetails.insurer"
      :placeholder="$t('installations.EnterInsurer')"
      :disabled="subscription === 'tryg' && curData.vehicleDetails.insurer === 'Tryg'"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.PoliceNo')"
      v-model="curData.vehicleDetails.policeNo"
      :placeholder="$t('installations.EnterPoliceNo')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.VehicleType')"
      v-model="curData.vehicleDetails.type"
      :placeholder="$t('installations.CarMotorbikeBoatEtc')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.Brand')"
      v-model="curData.vehicleDetails.brand"
      :placeholder="$t('installations.EnterVehicleBrand')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.Color')"
      v-model="curData.vehicleDetails.color"
      :placeholder="$t('installations.EnterVehicleColor')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.RegNo')"
      v-model="curData.vehicleDetails.regNo"
      :placeholder="$t('installations.EnterVehicleRegNo')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.Vin')"
      v-model="curData.vehicleDetails.vin"
      :placeholder="$t('installations.EnterVehicleVin')"
    />

    <!-- eslint-disable-next-line -->
    <mc-form-input
      type="text"
      :label="$t('installations.SpecialMarks')"
      v-model="curData.vehicleDetails.specialMarks"
      :placeholder="$t('installations.EnterVehicleSpecialMarks')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      curData: {},
    }
  },
  props: {
    value: Object,
    subscription: String,
  },
  created() {
    this.curData = this.value
  },
  watch: {
    value(newVal) {
      this.curData = newVal
    },
  },
}
</script>
