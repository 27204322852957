import { i18n } from '../setup/i18n'

let subscriptionOptions = [
  { value: 'standard', label: i18n.t('trackers.subscriptions.standard') }, //
  { value: 'light', label: i18n.t('trackers.subscriptions.light') },
  { value: 'free', label: i18n.t('trackers.subscriptions.free') },
  { value: 'insurance', label: i18n.t('trackers.subscriptions.insurance') },
  { value: 'tryg', label: i18n.t('trackers.subscriptions.tryg') },
]

let subscriptionObject = {}
for (var i in subscriptionOptions) {
  subscriptionObject[subscriptionOptions[i].value] = subscriptionOptions[i].label
}

export default {
  options: subscriptionOptions,
  object: subscriptionObject,
  isInsurance: function(subscription) {
    return ['insurance', 'tryg'].includes(subscription)
  },
}
