<template>
  <div>
    <ol :class="'order-tracker-list' + (editable ? ' editable' : '') + (details ? ' details' : '')">
      <li v-for="(tracker, i) in value" v-bind:key="i">
        <strong>{{ trackerTypeNames[tracker.type] }}</strong>
        <b-button-group v-if="editable" class="float-right">
          <McButton icon="edit" variant="secondary" size="sm" @click="addOrEditTracker(tracker, i)" />
          <McButton icon="trash" variant="danger" size="sm" @click="removeTracker(i)" />
        </b-button-group>
        <ul style="font-size: .8em">
          <li v-if="tracker.imei && !handleOrder">
            imei: <span class="badge badge-dark">{{ tracker.imei }}</span>
          </li>
          <li>
            Abb: <span class="badge badge-dark">{{ subscriptionNames[tracker.subscription] }}</span>
          </li>
          <li v-if="tracker.withInstallation == false"><del>Installation</del></li>
          <li v-else-if="tracker.withInstallation == true">
            Installation<span v-if="tracker.mechanicName">
              :
              <span class="badge badge-dark">{{ tracker.mechanicName }}</span>
            </span>
          </li>
          <li v-if="details && isInsurance(tracker.subscription)">{{ $t('general.InsuranceAlarmContacts') }}:
            <ul>
              <li>
                #1:
                <span v-if="tracker.insuranceSettings.contact1.phone">{{ tracker.insuranceSettings.contact1.name }}: {{ tracker.insuranceSettings.contact1.phone | FormatPhone }}</span>
                <span v-else>-</span>
              </li>
              <li>
                #2:
                <span v-if="tracker.insuranceSettings.contact2.phone">{{ tracker.insuranceSettings.contact2.name }}: {{ tracker.insuranceSettings.contact2.phone | FormatPhone }}</span>
                <span v-else>-</span>
              </li>
            </ul>
            {{ $t('installations.VehicleAndInsurance') }}:
            <ul>
              <li>{{ $t('installations.Insurer') }}: {{ tracker.installation.vehicleDetails.insurer | DashIfEmpty }}</li>
              <li>{{ $t('installations.PoliceNo') }}: {{ tracker.installation.vehicleDetails.policeNo | DashIfEmpty }}</li>
              <li>{{ $t('installations.VehicleType') }}: {{ tracker.installation.vehicleDetails.type | DashIfEmpty }}</li>
              <li>{{ $t('installations.Brand') }}: {{ tracker.installation.vehicleDetails.brand | DashIfEmpty }}</li>
              <li>{{ $t('installations.Color') }}: {{ tracker.installation.vehicleDetails.color | DashIfEmpty }}</li>
              <li>{{ $t('installations.RegNo') }}: {{ tracker.installation.vehicleDetails.regNo | DashIfEmpty }}</li>
              <li>{{ $t('installations.Vin') }}: {{ tracker.installation.vehicleDetails.vin | DashIfEmpty }}</li>
              <li>{{ $t('installations.SpecialMarks') }}: {{ tracker.installation.vehicleDetails.specialMarks | DashIfEmpty }}</li>
              <!--li>
                <pre>{{ tracker.installation.vehicleDetails }}</pre>
              </li-->
            </ul>
          </li>
        </ul>
        <!--pre>{{ tracker }}</pre-->
        <div v-if="handleOrder && availableTrackers !== null">
          <mc-form-select
            :value="tracker.mechanic"
            @input="e => setMechanic(i, e)"
            :label="$t('orders.Mechanic')"
            :placeholder="$t('orders.ChooseMechanic')"
            :options="mechanics"
            :icon="['fad', 'car-garage']"
            v-if="tracker.withInstallation"
          >
            <b-input-group-append slot="append">
              <McButton style="margin:1px" icon="plus" @click="$emit('addMechanic', { tracker: tracker, index: i })" />
            </b-input-group-append>
          </mc-form-select>

          <!-- eslint-disable-next-line -->
          <mc-form-select
            :value="tracker.imei"
            @input="e => setImei(i, e)"
            :label="$t('orders.Tracker')"
            :placeholder="$t('orders.ChooseTracker')"
            :options="trackerOptions[tracker.type]"
            :icon="['fad', 'cars']"
          >
            <b-input-group-append slot="append">
              <McButton style="margin:1px" icon="plus" @click="$emit('addTracker', { tracker: tracker, index: i })" />
            </b-input-group-append>
          </mc-form-select>

          <!-- eslint-disable-next-line -->
          <mc-form-date-picker
            name="dateExpires"
            :value="expiries[i]"
            @input="e => setExpiry(i, e)"
            :label="$t('trackerform.chooseExpiry')"
            :icon="['far', 'calendar']"
            :config="{ format: 'L', timeZone: 'GMT' }"
          />
        </div>
      </li>
    </ol>
    <!--pre>{{ value }}</pre-->
    <McButton v-if="editable" @click="addOrEditTracker({}, -1)" :text="$t('orders.AddTracker')" />
    <OrderTrackerForm @edit-completed="editTrackerComplete" ref="orderTrackerForm" :value="editingOrderTracker" :id="'orderTrackerForm' + _uid" isModal />
  </div>
</template>

<script>
import { FormatPhone } from '../helpers/Formatting'
import subscriptionTypes from '../helpers/SubscriptionTypes'
import trackerTypes from '../helpers/TrackerTypes'
import { gmtTime, mysqlTimeStringFromDate } from '../helpers/Time'
import moment from 'moment-timezone'

export default {
  props: {
    value: Array,
    details: Boolean,
    editable: Boolean,
    handleOrder: Boolean,
    mechanics: Array,
    availableTrackers: {
      validator: prop => {
        //console.log(typeof prop, prop)
        return (typeof prop === 'object' && Array.isArray(prop)) || prop === null
      },
    },
  },
  watch: {
    value(val) {
      this.handleNewValue(val)
    },
  },
  data() {
    return {
      subscriptionNames: subscriptionTypes.object,
      trackerTypeNames: trackerTypes.object,
      editingOrderTracker: {},
      editingOrderTrackerIndex: {},
      expiries: [],
    }
  },
  computed: {
    trackerOptions() {
      let ret = []
      for (var i in trackerTypes.object) {
        ret[i] = []
        ret[i].push({ value: -1, label: this.$t('orders.ChooseOrCreateTrackerType', { type: trackerTypes.object[i] }) })
      }
      for (var i in this.availableTrackers) {
        ret[this.availableTrackers[i].type].push({
          value: this.availableTrackers[i].imei,
          label: this.availableTrackers[i].name,
        })
      }
      for (var i in trackerTypes.object) {
        if (ret[i].length < 2) {
          ret[i][0].label = this.$t('orders.CreateTrackerTypeArrow', { type: trackerTypes.object[i] })
        }
      }
      return ret
    },
  },
  mounted() {
    //console.log('mounted')
    this.handleNewValue(this.value)
  },
  methods: {
    isInsurance: subscriptionTypes.isInsurance,
    handleNewValue(val) {
      if (val == null || !this.handleOrder) {
        return
      }
      //console.log('handlenewvalue')
      for (var i in val) {
        if (typeof val[i].dateExpires == 'undefined' || !val[i].dateExpires || val[i].dateExpires == '0000-00-00 00:00:00') {
          this.expiries[i] = moment()
            .tz('GMT')
            .add(val[i].subscription == 'tryg' ? 365 : 90, 'days')
            .startOf('day')
            .toDate()
        } else {
          this.expiries[i] = gmtTime(val[i].dateExpires)
        }
        val[i].dateExpires = mysqlTimeStringFromDate(this.expiries[i])

        if (typeof val[i].installation == 'object' && typeof val[i].installation.vehicleDetails == 'object' && val[i].installation.vehicleDetails.insurer == '' && val[i].subscription == 'tryg') {
          val[i].installation.vehicleDetails.insurer = 'Tryg'
        }

        if (typeof val[i].imei == 'undefined') {
          val[i].imei = '-1'
        }
        if (typeof val[i].mechanic == 'undefined') {
          val[i].mechanic = '-1'
        }
      }
    },
    setImei(index, imei) {
      //console.log('emit', 'imei', this.value[index].imei, imei)
      if (this.value[index].imei != imei && imei != -1) {
        let newVal = [ ...this.value ]
        newVal[index].imei = imei
        this.$emit('input', newVal)
      }
    },
    setMechanic(index, mechanicId) {
      //console.log('emit', 'mechanic')
      let newVal = [ ...this.value ]
      newVal[index].mechanic = mechanicId
      this.$emit('input', newVal)
    },
    setExpiry(index, date) {
      //console.log('emit', 'expiry', index, date)
      let newVal = [ ...this.value ]
      newVal[index].dateExpires = mysqlTimeStringFromDate(date)
      this.$emit('input', newVal)
    },
    removeTracker(index) {
      let newVal = []
      for (var i in this.value) {
        if (i != index) {
          newVal.push(this.value[i])
        }
      }
      this.$emit('input', newVal)
    },

    addOrEditTracker(item, index) {
      this.editingOrderTracker = { ...item }
      this.editingOrderTrackerIndex = index
      this.$bvModal.show('orderTrackerForm' + this._uid)
    },

    editTrackerComplete(tracker) {
      let newTracker = JSON.parse(JSON.stringify(tracker))
      if (this.isInsurance(newTracker.subscription)) {
        delete newTracker.insuranceSettings
        delete newTracker.installation
      } else {
        newTracker.withInstallation = true
      }
      if (newTracker.type != 30) {
        delete newTracker.withInstallation
      }

      let newVal = []
      for (var i in this.value) {
        if (i != this.editingOrderTrackerIndex) {
          newVal.push(this.value[i])
        } else {
          newVal.push(newTracker)
        }
      }

      if (this.editingOrderTrackerIndex < 0) {
        newVal.push(newTracker)
      }

      this.$emit('input', newVal)
    },
  },
  filters: {
    FormatPhone(val) {
      return FormatPhone(val)
    },
    DashIfEmpty(val) {
      return val ? val : '-'
    },
  },
}
</script>

<style>
ol.order-tracker-list.details {
  margin-left: 0;
  padding-left: 0;
  list-style-position: inside;
}
ol.order-tracker-list.details > li {
  margin: 0;
  padding: 0.5em;
}
ol.order-tracker-list.details > li:nth-child(even) {
  background: #d8d8d8;
}
ol.order-tracker-list.details > li:nth-child(odd) {
  background: #e8e8e8;
}
</style>
