<template>
  <McLayoutDefault :heading="heading">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministratorOfTrackers() && !$perms.hasTrackersWithAnyEditPerms() && !$perms.isAdministrator()">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <div v-else>
      <b-tabs class="mb-3" v-if="$perms.isAdministrator()">
        <b-tab :title="$t('trackers.tabAll')" @click="$router.push('/trackers')" :active="!$route.params.statusFilter"></b-tab>
        <b-tab :title="$t('trackers.tabActive')" @click="$router.push('/trackers/active')" :active="$route.params.statusFilter == 'active'"></b-tab>
        <b-tab :title="$t('trackers.tabExpired')" @click="$router.push('/trackers/expired')" :active="$route.params.statusFilter == 'expired'"></b-tab>
        <b-tab :title="$t('trackers.tabUnassigned')" @click="$router.push('/trackers/unassigned')" :active="$route.params.statusFilter == 'unassigned'"></b-tab>
      </b-tabs>
      <McTable class="trackers-table" ref="trackersTable" :query="$route.params.query" :sortBy="sortBy" :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
        <McButton @click="editTracker({})" variant="primary" v-if="$perms.isAdministrator()" icon="plus" :text="$t('trackers.add')" />
        <template v-slot:cell(name)="data">
          <McIcon v-if="data.item.icon" sized :style="{ color: data.item.color }" :icon="data.item.icon" />
          <span class="trackerName">{{ data.item.name }}</span>
          <div>
            <span v-if="data.item.autoRenew == 0" class="badge bg-danger text-white">{{ $t('trackers.CANCELLED') }}</span>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <McButton
              :icon="['fad', 'location']"
              variant="primary"
              :to="'/map/' + data.item.imei"
              :title="$t('trackers.ViewOnMap')"
              size="sm"
              v-if="TrackerStatusStore[data.item.imei] && ($perms.isAdministrator() || $perms.hasPermission(data.item.imei, 'currentPosition'))"
            />
            <!--pre>{{ TrackerStatusStore[data.item.imei] }}</pre-->
            <McButton
              icon="cog"
              variant="secondary"
              @click="editTracker(data.item)"
              :title="$t('trackers.Settings')"
              size="sm"
              v-if="$perms.isAdministrator() || $perms.isAdministratorOfTracker(data.item.imei) || $perms.hasPermission(data.item.imei, 'name') || $perms.hasPermission(data.item.imei, 'colorAndIcon')"
            />
            <!--pre>{{ data.item }}</pre-->
            <McButton
              icon="plus"
              variant="success"
              @click="upgradeTrackerDialog(data.item)"
              :title="$t('trackers.Upgrade')"
              size="sm"
              v-if="data.item.subscription === 'free' && $perms.isAdministratorOfTracker(data.item.imei)"
            />
            <McButton :icon="['fad', 'book']" :variant="data.item.noteCount > 0 ? 'lightblue' : 'outline-lightblue'" @click="editJournal(data.item)" size="sm" v-if="$perms.isAdministrator()" />
            <McButton
              :icon="['fas', 'file-pdf']"
              variant="storm"
              class="text-white"
              @click="editInstallation(data.item)"
              size="sm"
              v-if="data.item.isInsurance && $perms.isAdministrator()"
            />
            <McButton
              icon="edit"
              :title="$tc('trackers.AlarmButtonTitle', data.item.alarmRuleCount)"
              :variant="data.item.alarmRuleCount > 0 ? 'wine' : 'outline-wine'"
              @click="editTrackerAlarms(data.item)"
              size="sm"
              v-if="$perms.isAdministrator() || $perms.hasPermission(data.item.imei, 'alarmRules')"
            >
              <template slot="icon">
                <font-awesome-layers>
                  <font-awesome-icon icon="bell" transform="up-2 left-2" />
                  <font-awesome-icon icon="pen" transform="shrink-3 down-5 right-8" style="opacity: .7" />
                </font-awesome-layers>
              </template>
            </McButton>
            <McButton icon="trash" variant="danger" size="sm" @click="deletetracker(data.item.imei, data.item.name)" v-if="$perms.isAdministrator()" />
          </b-button-group>
        </template>
        <template v-slot:cell(status)="data">
          <p v-if="data.item.userId == 0" class="badge bg-warning text-white">{{ $t('trackers.UNASSIGNED') }}</p>
          <p v-else-if="data.item.hasExpired == 1" class="badge bg-danger text-white">{{ $t('trackers.EXPIRED') }}</p>
          <b-button-group v-else-if="$perms.isAdministrator() || $perms.hasPermission(data.item.imei, 'currentPosition')">
            <TrackerStatus list :imei="data.item.imei" />
          </b-button-group>
        </template>
        <template v-slot:cell(dateExpires)="data">
          <span v-if="data.item.userId != 0">{{ data.item.dateExpires | shortDate }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(ownerContactName)="data">
          <span v-if="data.item.userId != 0">{{ data.item.ownerContactName }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(billingContactName)="data">
          <span v-if="data.item.userId != 0">{{ data.item.billingContactName }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(userName)="data">
          <span v-if="data.item.userId != 0">{{ data.item.userName }}</span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(niceType)="data">
          {{ data.item.niceType }}
        </template>
        <template v-slot:cell(dateCreated)="data">{{ data.item.dateCreated | shortDate }}</template>
      </McTable>
      <TrackerForm id="addtrackerform" isModal :value="editingTracker" @completed="refreshTable()" />
      <TrackerAlarmsForm ref="trackeralarmsform" id="trackeralarmsform" isModal @completed="refreshTable()" />
      <TrackerInstallationForm ref="installationform" id="installationform" isModal @completed="refreshTable()" />
      <JournalForm ref="journalform" id="journalform" isModal />
    </div>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'
import TrackerService from './../../services/TrackerService'
import { dateToGMTHumanreadableDate } from '../../helpers/Time'
import { niceCurrency } from '../../helpers/Formatting'

export default {
  filters: {
    shortDate(v) {
      return dateToGMTHumanreadableDate(v)
    },
  },
  data() {
    return {
      primaryKey: 'imei',
      sortBy: 'name',
      editingTracker: {},
    }
  },
  computed: {
    ...mapState({
      TrackerStatusStore: state => state.TrackerStatusStore.trackers,
    }),
    heading() {
      if (this.$route.params.statusFilter) {
        return this.$t('headings.trackersSub.' + this.$route.params.statusFilter)
      }
      return this.$t('headings.trackers')
    },
    fields() {
      return [
        { key: 'name', priority: 0, mw: 200, sortable: true, label: this.$t('trackers.name') }, //
        { key: 'niceType', priority: 3, mw: 120, sortable: true, label: this.$t('trackers.type') },
        { key: 'userName', mw: 200, sortable: true, label: this.$t('general.adminUser') },
        { key: 'ownerContactName', mw: 200, sortable: true, label: this.$t('trackers.ownerContact') },
        { key: 'billingContactName', mw: 200, sortable: true, label: this.$t('trackers.billingContact') },
        { key: 'imei', mw: 200, sortable: true, label: this.$t('trackers.imei') },
        { key: 'dateCreated', mw: 200, sortable: true, label: this.$t('trackers.datecreated'), formatter: v => dateToGMTHumanreadableDate(v) },
        { key: 'dateExpires', mw: 200, sortable: true, label: this.$t('trackers.dateexpires'), formatter: v => dateToGMTHumanreadableDate(v) },
        { key: 'status', priority: 0, mw: 250, label: this.$t('trackers.status'), class: 'text-center' },
        { key: 'actions', priority: 0, mw: 200, label: this.$t('general.Actions'), class: 'text-right' },
      ]
    },
  },
  watch: {
    '$route.params.statusFilter'() {
      this.refreshTable()
      this.$refs.trackersTable.resetPagination()
    },
  },
  methods: {
    dataProvider(ctx) {
      if (this.$route.params.statusFilter) {
        ctx.statusFilter = this.$route.params.statusFilter
      } else {
        ctx.statusFilter = null
      }
      return TrackerService.query(ctx)
    },
    refreshTable() {
      this.$refs.trackersTable.refresh()
    },
    editTracker(item) {
      //console.log(item)
      this.editingTracker = { ...item }
      this.$bvModal.show('addtrackerform')
    },
    editJournal(item) {
      this.$refs['journalform'].setImei(item.imei)
      this.$bvModal.show('journalform')
    },
    editInstallation(item) {
      //console.log(item)
      this.$refs['installationform'].setImei(item.imei)
      this.$bvModal.show('installationform')
    },
    editTrackerAlarms(item) {
      //console.log(item)
      this.$refs['trackeralarmsform'].setImei(item.imei)
      this.$bvModal.show('trackeralarmsform')
    },
    upgradeTrackerDialog(item) {
        TrackerService.upgradePrice(item.imei).then(data => {
          this.$bvModal.msgBoxConfirm(this.$t('trackers.AnUpgradeInvoiceWillBeCreated', { price: niceCurrency(data.price, data.currency) })).then((v) => {
            if (!v) {
              return
            }
            TrackerService.upgrade(item.imei).then(data => {
              this.$router.push('/billing/unpaid')
            })
          })
        })
    },
    deletetracker(imei, name) {
      if (confirm(this.$t('trackers.confirmdelete', { name }))) {
        TrackerService.delete(imei).then(data => {
          this.refreshTable()
        })
      }
    },
  },
}
</script>
